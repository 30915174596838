<template>
  <div class="audioUpload">
    <div class="condition_style2">
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-form-item label="音频名称：" prop="fileTitle" >
          <el-input class="w_250" v-model="addForm.fileTitle" placeholder="请输入名称"   maxlength="18"></el-input>
        </el-form-item>
        <el-form-item label="音频描述:" prop="fileDesc">
            <el-input class="w_250" type="textarea" v-model="addForm.fileDesc" :rows="4" placeholder="请输入描述" maxlength="300" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <el-table :data="tables" style="width: 100%" class="table" ref="multipleTable"  @selection-change="handleSelectionChange" :height="tableHeight">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" label="序号" width="60" align="center" fixed>
          <template slot-scope="scope">{{scope.$index+(current-1) * size + 1}}</template>
        </el-table-column>
         <el-table-column prop="fileName" label="文件服务器原名称" min-width="120" align="center"></el-table-column>
        <!-- <el-table-column prop="original" label="视频描述" min-width="120" align="center"></el-table-column> -->
        <el-table-column prop="fileSize" label="大小" min-width="120" align="center"></el-table-column>
        <!-- <el-table-column fixed="right" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <div>
            <el-button type="text" size="small" class="ge" @click="remove(scope.$index,scope.row)">删除</el-button>
            <el-button type="text" size="small" class="ge" @click="reUpload(scope.row)">重新上传</el-button>
            <el-button type="text" size="small" class="ge" @click="changeState(scope.row)">取消</el-button>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
         <div class="bottom">
            <!-- <el-upload class="upload" :multiple="true" ref="upload" :action="action2"
            accept=".mp4" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :headers="headers">
            <el-button slot="trigger" size="small" type="primary">继续添加视频</el-button>
            </el-upload> -->
            <!-- <el-button type="primary"  size="small" @click="uploadVideo()">继续添加视频</el-button> -->
            <el-button type="primary"  size="small" @click="finish()">提交</el-button>
        </div>
    </div>
  </div>
</template>

<script>
  import pagination from "../../../../components/pagination";
  import modal from "../../../../components/modal";
  import {files2,files1,fileSave} from "../../../../api/api";
  export default {
    components: {
      pagination,
      modal,
    },
    props:['tables'],
    name:"videoUpload",
    data() {
      return {
        tables:[],
        size:10,//每页条数
        current:1,//当前条数
        total: 0,
        tableHeight: this.$util.getHeight(510),
        searchForm: {
        },
        tableData: [],
        addDialog: false,
        edit: "",
        addForm: {
          fileTitle: "",
          fileDesc: "",
          extensionName: "",
          format: "",
          fileName: "",
          bucketName: "",
          original: "",
          type:3,
          url:"",
        },
        rules: {
          fileTitle: [{
              required: true,
              message: "请输入音频名称"
              }],
           },
      };
    },
    computed: {
    },
 
    created() {
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.tableHeight = this.$util.getHeight(310)
      })
      this.addForm = this.tables[0]
      this.addForm.type = 3
      // this.addForm.fileTitle = this.tables[0].fileName
      console.log(this.tables[0],"this.tables[0]")
    },
    methods: {
      //重新上传
       reUpload(val){
    
      },
      handleSelectionChange(e){
          console.log(e,"eeee")

      },
      //删除
      remove(index){
           this.tableData.splice(index, 1);
       
      },
      //继续添加视频
      uploadVideo(){
          this.addDialog = true
      },
      //完成
      finish(){
        this.$refs.addForm.validate((result) => {
        if (result) {
        this.$request(fileSave,this.addForm).then((res)=>{
          if(res.code==0&&res.data==true){
            this.$util.message("suc","操作成功")
            this.$router.push({
              name:"audio"
            })
          }
        })
        }
      }) 
      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.current = val;
        this.getList();
      },
    },
  };
</script>

<style lang="less" scoped>
  .audioUpload {
    .condition_style2{
      margin-top: 20px;
    }
    .upload{
      display: inline;
      margin-right:10px;
    }
    .bottom{
        height: 50px;
        width: 100%;
        text-align: center;
        margin-top: 30px;
    }
    .mar_p{
       padding-top: 20px;  
    }
  }
</style>
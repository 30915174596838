var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dictionary"},[_vm._m(0),_c('el-card',{staticClass:"condition_style2"},[_c('el-steps',{attrs:{"active":_vm.active,"simple":"","align-center":""}},_vm._l((_vm.stepList),function(item,i){return _c('el-step',{key:i,attrs:{"title":item.name},nativeOn:{"click":function($event){return _vm.clickStep(item)}}})}),1),_c('div',{staticClass:"com_style"},[_c(_vm.currentTabComponent,{tag:"component",attrs:{"tables":_vm.tableList},on:{"pushUpload":_vm.changeActive}})],1)],1),_c('modal',{attrs:{"obj":{
      title:  '上传',
      dialog: _vm.addDialog,
      dialogText: 'addDialog',
      form: _vm.addForm,
      ref: 'addForm',
    }},on:{"submit":_vm.addSubmit}},[_c('el-form',{ref:"addForm",attrs:{"model":_vm.addForm,"label-width":"130px"}},[_c('el-form-item',{attrs:{"label":"名称：","prop":"fileName"}},[_c('el-input',{attrs:{"placeholder":"请输入名称","maxlength":"18"},model:{value:(_vm.addForm.fileName),callback:function ($$v) {_vm.$set(_vm.addForm, "fileName", $$v)},expression:"addForm.fileName"}})],1),_c('el-form-item',{attrs:{"label":"视频描述","prop":"introduce"}},[_c('el-input',{attrs:{"type":"textarea","rows":4,"placeholder":"请输入简介","maxlength":"300","show-word-limit":""},model:{value:(_vm.addForm.introduce),callback:function ($$v) {_vm.$set(_vm.addForm, "introduce", $$v)},expression:"addForm.introduce"}})],1),_c('el-form-item',{attrs:{"label":"视频文件：","prop":"url"}},[_c('div',{staticClass:"flex"},[_c('el-upload',{ref:"uploader",attrs:{"action":_vm.action2,"accept":".mp4","headers":_vm.headers,"show-file-list":false,"on-success":_vm.handleAvatarSuccess,"before-upload":_vm.beforeAvatarUpload}},[_c('el-button',{attrs:{"size":"small","type":"primary","loading":_vm.loading}},[_vm._v(_vm._s(!_vm.loading?"本地上传":"上传中"))])],1)],1),_c('div',[(_vm.addForm.url)?_c('video-player',{ref:"videoPlayer",staticClass:"video-player vjs-custom-skin",attrs:{"playsinline":true,"options":_vm.playerOptions}}):_vm._e()],1)])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("音频库 / ")]),_vm._v(" "),_c('span',{staticClass:"black"},[_vm._v("音频上传")])])
}]

export { render, staticRenderFns }
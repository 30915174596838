<template>
  <div class="selectVideo">
    <div class="condition_style2">
      <el-form :inline="true" class="flex mar_p" :model="searchForm">
        <el-form-item >
          <el-upload class="upload" :multiple="true" ref="upload" action="#"
          :file-list="fileList" :auto-upload="false" accept=".mp3" :show-file-list="false" :on-change="onchange">
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          <span slot="tip" class="el-upload__tip">只能上传MP3文件，且不超过300kb</span>
        </el-upload>
        </el-form-item>
      </el-form>
   
      <el-table :data="tableData" v-loading="loading"  element-loading-text="正在上传中..."  element-loading-spinner="el-icon-loading" style="width: 100%;" class="table" ref="multipleTable"  @selection-change="handleSelectionChange" :height="tableHeight">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" label="序号" width="60" align="center" fixed>
          <template slot-scope="scope">{{scope.$index+(current-1) * size + 1}}</template>
        </el-table-column>
        <el-table-column prop="name" label="视频描述" min-width="120" align="center"></el-table-column>
        <el-table-column prop="type" label="视频格式" min-width="120" align="center"></el-table-column>
        <el-table-column prop="size" label="大小" min-width="120" align="center"></el-table-column>   
        <el-table-column fixed="right" label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" class="ge" @click="onRemove1(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <pagination :page="searchForm.page" :size="searchForm.limit" :total="total" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"></pagination> -->
        <div class="bottom">
            <!-- <el-button type="primary"  size="small" @click="uploadVideo()">继续添加视频</el-button> -->
            <el-button type="primary"  size="small" :disabled="tableData.length>0?false:true" @click="upVideo()">开始上传</el-button>
            <!-- <el-button type="primary"  size="small" @click="add1">取消</el-button> -->
        </div>
    </div>
  </div>
</template>

<script>
  import pagination from "../../../../components/pagination";
  import modal from "../../../../components/modal";
  import {
   fileList3,files1,fileUpdate,fileSave
  } from "../../../../api/api";
  export default {
    name:"selectVideo",
    components: {
      pagination,
      modal,
    },
    data() {
      return {
        size:10,//每页条数
        current:1,//当前条数
        total: 0,
        tableHeight: this.$util.getHeight(510),
        searchForm: {
        },
        fileList:[],
        tableData: [],
        edit: "",
        loading: false
      };
    },
    computed: {
    },
 
    created() {
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.tableHeight = this.$util.getHeight(310)
      })
    },
    methods: {
        //继续添加视频
    uploadVideo(){
    },
      upVideo(){
        this.loading = true
         console.log(this.fileList,"file,fileList上传文件被改变")
          let formData = new FormData()
          let list  = []
          // 因为要传一个文件数组过去，所以要循环append
          this.fileList.forEach((file,index) => {
            formData.append(`files`, file.raw)
          })
        this.uploadHttp(formData)
      },
      //接口上传
      uploadHttp(formData){
          this.$request(fileList3,formData).then(res => {
          console.log(res,"res")
          if (res.code == 0) {
            // 上传成功的操作
              this.$emit("pushUpload",res.data)
              this.loading = false
          } else {
          }
          this.fileList = []
        })
      },
      onchange(file,fileList){
        this.tableData = fileList.map((val)=>{
          return{
            name:val.name,
            type:val.raw.type,
            size:val.size,
          }
        })
        this.fileList = fileList 

      },
      //移除
      onRemove1(index){
       this.fileList = this.tableData.splice(index, 1);
        
      },
      handleSelectionChange(e){
          console.log(e,"eeee")

      },
      remove(){
        this.$forceUpdate();
       
      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.current = val;
        this.getList();
      },
    },
  };
</script>

<style lang="less" scoped>
  .selectVideo {
    .add {
      padding-top: 3px;
      margin-right: 24px;
    }
    .el-upload__tip{
      margin-left: 30px;
    }
    .upload{
      display: inline;
      margin-right:10px;
    }
    .bottom{
        height: 50px;
        width: 100%;
        text-align: center;
        margin-top: 30px;
    }
    .mar_p{
       padding-top: 20px;  
    }
  }
</style>